import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import LogIn from './view/LogIn';
import Home from './view/Home';
import { CookiesProvider, withCookies, Cookies } from 'react-cookie';
import propTypes, { instanceOf } from 'prop-types';
import config from './config';

class App extends Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  state = {
    login: this.props.cookies.get('user') || '',
    currentLanguage: '',
    count: 0,
    user: {
      api_key: config.api_key,
      data: {
        message: [],
        url: 'https://web.italkyou.com/#/',
        user_id: config.user_id,
        idworker: this.props.cookies.get('idworker') || ''
      },
      locale_Code: '',
      prmHash: '',
      session_key: config.session_key
    },
    dataUserPush: []

  };

  logInUser = (user) => this.setState({ login: user });

  updateIdWorker = (idworker) => {
    const { user } = this.state;
    const { data } = user;
    this.setState({
      user: {
        ...user,
        data: {
          ...data,
          idworker: idworker.toString()
        }
      }
    })
  }

  getUserDataPush = () => {
    const { locale_Code, data } = this.state.user;
    const { idworker } = data;

    fetch(`${config.url}/api/v1/PushList`, {
      headers: {
        "Content-Type": "application/json",
        "api_key": config.api_key,
        "session_key": config.session_key,
        "user_id": config.user_id,
        "paginate": "1",
        "count": "1000000",
        "idworker": idworker.toString()
      }
    }).then(res => res.json())
      .then(data => {
        if (data.resp_cod === 0) {
          const { files, count } = data.data;

          const newData = files.map((messagePush, id) => {
            return {
              message: messagePush.message,
              date: messagePush.date,
              id: id + 1
            }
          })

          this.setState({ dataUserPush: newData, count })
        }
      })
      .catch(err => console.error(err))
  }

  updateSession = (e) => {
    const { name, value } = e.target;

    this.setState({
      user: {
        ...this.state.user,
        [name]: value
      }
    });
  }

  loadind = () => this.state.load;

  addPush = async (objMessages) => {
    const { user } = this.state;
    const { locale_Code, data } = user;
    const { idworker } = data;
    data.message = objMessages;
    data.idworker = idworker.toString();

    let validate = false;

    await fetch(`${config.url}/api/v1/PushAll`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        dataType: 'json',
        body: JSON.stringify(user)
        // no-cors
      })
      .then(res => res.json())
      .then(dataPush => {

        if (dataPush.resp_cod === 0) {

          this.getUserDataPush();
          this.setState({
            user: {
              ...user,
              data: {
                ...data,
                message: []
              }
            },
            load: true
          })
          validate = true;
        } else {
          window.alert(locale_Code === 'es' ? 'Hubo un error en la ejecución de su envío' : 'There was an error in the execution of your send')
        }
      })
      .catch(err => {
        console.error(err);
        window.alert(locale_Code === 'es' ? 'Hubo un error en la ejecución de su envío' : 'There was an error in the execution of your send')
      })
    return validate
  }

  componentWillMount() {
    const { user } = this.state;
    const language = navigator.language.split('-')

    this.setState({
      user: {
        ...user,
        locale_Code: language[0]
      }
    });
  }

  componentDidMount() {
    const { idworker } = this.state.user.data;
    if (idworker) this.getUserDataPush();
  }

  render() {
    const { locale_Code } = this.state.user;
    // const { message } = data;
    const { dataUserPush, login, count } = this.state;
    const { cookies } = this.props;

    return (
      <CookiesProvider>
        <Router basename={config.base}>
          <Switch>
            <Route
              path="/LogIn" exact
              render={() =>
                <LogIn
                  updateSession={this.updateSession}
                  updateTable={this.getUserDataPush}
                  updateIdWorker={this.updateIdWorker}
                  // user={session_key}
                  logInUser={this.logInUser}
                  log={locale_Code === 'es' ? 'Iniciar sesión' : 'Log in'}
                  extention={locale_Code === 'es' ? 'Extensión, celular o e-mail' : 'Anexo, phone, e-mail'}
                  pass={locale_Code === 'es' ? 'Contraseña' : 'Password'}
                  btn={locale_Code === 'es' ? 'Ingresar' : 'Enter'}
                  lang={locale_Code}
                  cookies={cookies}
                />}
            />
            <Route
              path="/Home" exact
              render={() => login ?
                <Home
                  addPush={this.addPush}
                  // secText={locale_Code === 'es' ? 'The notification in english' : 'La notificacón en español'}
                  send={locale_Code === 'es' ? 'Enviar' : 'Send'}
                  title={locale_Code === 'es' ? 'Admin. de notif. masivas' : 'Mass notification manager'}
                  date={locale_Code === 'es' ? 'Fecha de env.' : 'Sending date'}
                  message={locale_Code === 'es' ? 'Mensaje' : 'Message'}
                  receptor={locale_Code === 'es' ? 'N° de posibles recept.' : 'N° of possible receiv.'}
                  titleAlert={locale_Code === 'es' ? 'Tenga en cuenta' : 'Keep in mind'}
                  messageAlert={locale_Code === 'es' ? `1.Para escribir un nuevo mensaje, primero debes seleccionar el idioma.\n 2.Esta página solo esta disponible en español y en inglés, pero los mensajes se pueden mandar hasta en 5 idiomas. Gracias por su comprensión.` : `1. To write a new message you should select the language first. \n2. This page is only available in Spanish and English, but messages can be sent in up to 5 languages. Thanks for your understanding`}
                  lang={locale_Code}
                  count={count}
                  out={locale_Code === 'es' ? 'Salir' : 'Log out'}
                  // load={load}
                  // updateMessage={this.updateMessage}
                  dataRows={dataUserPush}
                  table={locale_Code === 'es' ? 'Todavía no has enviado ninguna notificación' : 'You have not sent any notification yet'}
                /> :
                <Redirect to="/LogIn" />}
            />
          </Switch>
        </Router>
      </CookiesProvider>
    );
  }
}

export default withCookies(App);
