import React from 'react';

const Modal = ({ openRow, lang, id }) => {
  let arrMessage = [];
  const verifyArr = Array.isArray(openRow);

  if (verifyArr) arrMessage = openRow;
  else arrMessage = Object.keys(openRow);

  return (
    <div className="modal" id={`showMessage${id}`} aria-hidden="true" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{lang === 'es' ? 'Mensajes' : 'Message'}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {verifyArr ?
              arrMessage.map(({ language, value }) => <p key={language}>{language.toUpperCase()}: {value}</p>)
              : arrMessage.map(langs => <p key={langs}>{langs.toUpperCase()}: {openRow[langs]}</p>)}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">{lang === 'es' ? 'Salir' : 'Close'}</button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Modal;