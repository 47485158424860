const config = {
  api_key: 'Se3c9raP3h3BRiRIGKvdiR1CMeWx5VC9JMOHkIu+g9s=',
  user_id: '586fac7ecb1e8a1b2c14c4b9',
  session_key: '/3moLqGHwYdkTunXXEeztsm',
  apikey: '134778564565465',
  url: 'https://threads.inticousa.com',
  base: 'push-promocional/build'
}

export default config;

