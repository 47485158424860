import React, { Fragment, Component } from 'react';
// import Row from '../component/Row';
// import Table from '../component/Table'
import Form from '../component/Form';
// import Col from '../component/Col';
import Modal from '../component/Modal';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { withCookies } from 'react-cookie';
import PropTypes from 'prop-types';

class Home extends Component {

  formatMessage = (cell, row) => cell !== undefined ?`<button type="button" data-toggle="modal" data-target="#showMessage${row.id}" class="btn btn-info">Info</button>`: this.props.table;
  formatDate = (cell) => `${(new Date(cell)).toString().split(/GMT/g)[0].trim()}`

  static contextTypes = {
    router: PropTypes.object
  }

  options = {
    sizePerPage: 10,
    paginationSize: 3,
    hideSizePerPage: true,
    defaultSortName: 'id',
    defaultSortOrder: 'desc'
  };

  logOut = () => {
    const { cookies } = this.props;
    cookies.remove('user', { path: '/' });
    cookies.remove('idworker', { path: '/' });
    this.context.router.history.replace('/LogIn');
  }

  render() {
    const { addPush, send, title, date, message, receptor, count, titleAlert, messageAlert, dataRows, table, lang, out } = this.props
    return (
      <Fragment>
        <header className="text-white bg-magenta p-2 d-flex justify-content-between align-items-center">
          <h4 className="mb-0">{title}</h4>
          <button type="button" onClick={this.logOut} className="btn btn-danger w-auto  h-auto">{out}</button>
        </header>
        <main className="row m-0">
          <div className="col-md-1"></div>
          <div className="col-md-11">
            <div className="row m-0">
              <Form send={send} addPush={addPush} lang={lang} />
              <div className="col-md-4 p-md-0">
                <div className="alert alert-warning my-2" role="alert">
                  <h4 className="alert-heading">{titleAlert}</h4>
                  <p className="mb-0">{messageAlert}</p>
                </div>
              </div>
            </div>
            <BootstrapTable
              data={dataRows.length ? dataRows : [{ id: table}]}
              pagination={true}
              className="mx-3 my-2 table-hover"
              options={this.options}>
              <TableHeaderColumn dataField='id' isKey={true} width="65" dataSort>#</TableHeaderColumn>
              <TableHeaderColumn dataField='date' dataFormat={this.formatDate} dataSort>{date}</TableHeaderColumn>
              <TableHeaderColumn dataField='message' dataFormat={this.formatMessage} dataSort>{message}</TableHeaderColumn>
            </BootstrapTable>
            {dataRows.map(row => <Modal key={row.id} lang={lang} openRow={row.message} id={row.id} />)}

          </div>
        </main>
      </Fragment>
    )
  }
}

export default withCookies(Home);