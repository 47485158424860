import React, { Component } from 'react';
import PropTypes from 'prop-types';
import crypto from 'crypto';
import { withCookies } from 'react-cookie';
import config from '../config'

let passUser = '';
let email = '';

class LogIn extends Component {

  static contextTypes = {
    router: PropTypes.object
  }

  sendSegurity = (e) => {

    const key = '=)(/&%$##$%&/()=)/&%DFGDFGFGDF#$#%$%$#%#$%';
    const cipher = crypto.createCipher('aes256', key);
    const passKey = cipher.update(passUser.value, 'utf8', 'hex') + cipher.final('hex');

    const { lang, logInUser, updateTable, cookies, updateIdWorker } = this.props;

    const body = {
      email: email.value,
      password: passKey,
      apikey: config.apikey
    }

    e.preventDefault();

    fetch(`${config.url}/api/v1/auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(res => res.json())
      .then(res => {

        if (res.error === null) {
          const user = res.response;
          logInUser(user.name);
          updateIdWorker(user.idworker.toString());

          const date = new Date((new Date).getTime() + 86400 * 1000);

          cookies.set('user', user.name, { path: '/', expires: date });
          cookies.set('idworker', user.idworker.toString(), { path: '/', expires: date })
          this.context.router.history.replace('/Home');
          updateTable();

        } else window.alert(lang === 'es' ?
          'El usuario o contraseña incorrectos'
          : 'The incorrect username or password');
      })
      .catch(err => window.alert(lang === 'es' ?
        'Hubo un error al conectarse'
        : 'There was an error connecting')
      )
  }
  render() {
    const { log, extention, pass, btn } = this.props;

    return (
      <div className="content">
        <div className="row m-0 justify-content-center align-items-center view-all">
          <div className="col-md-4"></div>
          <div className="col-md-4 mb-4">
            <div className="mb-4">
              <div className="text-center m-3">
                <img className="img-h" src="https://web.italkyou.com/assets/images/logo.svg" alt="Logo iTalkYou" />
              </div>
              <div className="card">
                <div className="card-header text-white bg-magenta">{log}</div>
                <div className="card-body">
                  <form onSubmit={this.sendSegurity}>
                    <div className="form-group">
                      <input className="form-control" placeholder={extention} ref={input => { email = input }} />
                    </div>
                    <div className="form-group">
                      <input type="password" className="form-control" placeholder={pass} ref={input => { passUser = input }} />
                    </div>
                    <input type="hidden" name="i_language" value="1" />
                    <button type="submit" className="btn btn-success" >{btn}</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    )
  }
}

export default withCookies(LogIn);
