import React, { Component } from 'react';

const objLanguages = {
  'es': 'Notificación',
  'en': 'Notification',
  'fr': 'Notification',
  'it': 'Notifica',
  'pt': 'Notificação'
};

class Form extends Component {

  state = {
    texts: [],
    currentLang: this.props.lang,
    principalMessage: ''
  }

  addTextarea = (id) => {
    const { texts } = this.state;
    const arrLang = Object.keys(objLanguages);
    let btnAdd = true;

    texts.splice(id, 0, { id: (new Date).getTime(), disabled: true, lang: '', message: '', placeholder: '', btnAdd });

    texts.forEach(text => {
      if (texts.length === arrLang.length - 1)
        text.btnAdd = false;
    })

    this.setState({ texts });
  }

  updatePlaceholder = (e, id) => {
    const { lang } = this.props;
    const { value } = e.target;
    const { currentLang, texts } = this.state;

    const evaluation = texts.filter(({ lang }) => lang === value)

    if (value === currentLang || evaluation.length) {

      texts[id].disabled = true;
      texts[id].placeholder = objLanguages[value];
      texts[id].lang = value;

      window.alert(lang === 'es' ?
        'No puede haber 2 mensajes con el mismo idioma'
        : 'There can not be 2 messages with the same language');

    } else {

      texts[id].placeholder = objLanguages[value];
      texts[id].lang = value;
      texts[id].disabled = false;
    };

    this.setState({ texts });
  };

  deleteTextarea = (i) => {
    const { texts } = this.state;
    const arrLang = Object.keys(objLanguages)
    texts.splice(i, 1);

    texts.forEach(text => {
      if (texts.length === arrLang.length - 2) {
        text.btnAdd = true
      }
    })

    this.setState({ texts });
  }

  updateMessage = (e, id) => {
    const { value, name } = e.target;
    const { currentLang, texts } = this.state;

    if (name === currentLang) this.setState({ principalMessage: value });

    if (id !== undefined) {
      texts[id].message = value;
      this.setState({ texts })
    }
  }

  sendMessage = async(e) => {
    e.preventDefault();
    const { currentLang, texts, principalMessage } = this.state;
    const { addPush } = this.props;
    const { lang } = this.props;

    const verifyMsn = texts.filter(({ message, disabled }) => message.trim().length === 0 || disabled);

    if (principalMessage.trim().length === 0) {
      window.alert(lang === 'es' ?
        'Debes colocar al menos un mensaje'
        : 'You must place at least one message')

    } else if (verifyMsn.length) {
      window.alert(lang === 'es' ?
        'Tienes uno o más cuadros sin completar, agrega un mensaje o eliminalo'
        : 'You have one or more tables that are not complete, add a message or delete it')

    } else {

      const arrMessage = [];

      arrMessage.push({ language: currentLang, value: principalMessage });
      texts.forEach(({ lang, message }) => arrMessage.push({ language: lang, value: message }));
      const validate = await addPush(arrMessage);
      if (validate) { this.setState({ texts: [], principalMessage: '' }) }
    }
  }

  updateCurrentLang = (e) => {
    const { texts } = this.state;
    const { value } = e.target;
    const { lang } = this.props;

    let { currentLang } = this.state;

    let verify = true
    let i = 0;
    currentLang = value;

    while (i < texts.length) {

      if (texts.length) {
        const objText = texts[i];
        const value = objText.lang;

        if (value === currentLang) {

          texts[i].disabled = true;
          texts[i].placeholder = objLanguages[value];
          texts[i].lang = value;

          window.alert(lang === 'es' ?
            'No puede haber 2 mensajes con el mismo idioma'
            : 'There can not be 2 messages with the same language');

        } else if (value !== '') {

          texts[i].placeholder = objLanguages[value];
          texts[i].lang = value;
          texts[i].disabled = false;
        };
      };
      i += 1
    }
    this.setState({ currentLang, texts });
  }

  render() {
    const { send } = this.props;
    const { texts, currentLang, principalMessage } = this.state;

    return (
      <form className="col-md-8 mt-3 mb-4 form-group " onSubmit={this.sendMessage}>
        <div className="">
          {/* <span className="text-secondary pl-2 ">{currentLang.toLocaleUpperCase()}</span> */}
          <select className="form-control form-control-sm" onChange={this.updateCurrentLang}>
            <option selected >{currentLang.toLocaleUpperCase()}</option>
            {Object.keys(objLanguages).map(lang =>
              currentLang === lang ? null
                : <option key={lang} value={lang}>{lang.toLocaleUpperCase()}</option>
            )}
          </select>
          <textarea className="form-control border-top" value={principalMessage} placeholder={objLanguages[currentLang] === undefined ? objLanguages['en'] : objLanguages[currentLang]} rows="4" name={currentLang} onChange={this.updateMessage} />
        </div>
        <button className="btn btn-info rounded-0 py-1 px-2 my-2" disabled={texts.length < 4 ? false : true} type="button" onClick={() => this.addTextarea(0) }><i className="fas fa-plus"></i></button>
        {texts.length ?
          texts.map(({ id, message, disabled, lang, placeholder, btnAdd }, i) =>
            <div key={id}>
              <textarea name={lang} className="form-control mt-3" placeholder={placeholder} value={message} disabled={disabled} onChange={(e) => this.updateMessage(e, i)} />
              <div className="d-flex align-items-center">
                <button className={`btn btn-info rounded-0 py-1 px-2 `} disabled={!btnAdd} type="button" onClick={() =>  this.addTextarea(i + 1) }><i className="fas fa-plus"></i></button>
                <select onChange={(e) => this.updatePlaceholder(e, i)} className="form-control form-control-sm w-auto m-2">
                  <option disabled selected hidden>Lang</option>
                  {Object.keys(objLanguages).map(lang =>
                    <option key={lang} value={lang}>{lang.toLocaleUpperCase()}</option>)}
                </select>
                <button className="btn btn-danger py-1 px-2 rounded-0" type="button" onClick={() => this.deleteTextarea(i)}><i className="fas fa-trash"></i></button>
              </div>
            </div>
          ) : null}
        <div className="d-flex justify-content-end">
          <button className="btn btn-success d-block mt-2" type="submit">{send}</button>
        </div>
      </form>
    )
  }
}

export default Form;
